.loading {
  background: url(/sites/esteelauder/themes/estee_base/img/ajax-loading.gif) no-repeat center center;
  * {
    opacity: 0.8;
  }
}

.checkout {
  &.viewcart {
    .signin-to-see-cart {
      margin: 30px 0px 0px 0px;
      text-align: center;
    }
    #viewcart-panel {
      padding-top: 20px;
      .messages {
        background-color: $color-white;
        border-bottom: 1px solid $color-white;
        color: $color-red;
        margin-top: 20px;
        padding: 0 13px;
      }
      .viewcart-buttons {
        width: 100%;
        margin: 10px 0 20px;
        padding-top: 20px;
        border-top: 1px solid $color-white;
        padding-bottom: 20px;
        text-align: right;
        clear: both;
        .continue-buttons {
          width: 100%;
          display: block;
          padding: 0 13px;
          .left {
            text-align: #{$ldirection};
            position: relative;
            padding-bottom: 10px;
            line-height: 40px;
            float: left;
          }
          .continue_shopping {
            color: $color-black;
            font-weight: bold;
            line-height: 40px;
            border: 1px solid $color-black;
            padding: 0 5px;
            display: inline-block;
          }
          .continue-checkout {
            background: $color-black url(/media/images/global/white_right_arrow.png) no-repeat scroll right center;
            color: $color-white;
            font-weight: bold;
            padding: 5px 31px 5px 15px;
            margin-left: 15px;
            height: 40px;
            line-height: 30px;
            display: inline-block;
          }
        }
      }
      .viewcart-panel__content {
        float: left;
        .cart-items {
          .cart-header {
            padding: 15px 13px;
            width: 100%;
            border-top: 1px solid $color-white;
            border-bottom: 1px solid $color-white;
            float: left;
          }
          .cart-items-empty {
            color: $color-red;
            height: 100px;
            font-size: 26px;
            padding-top: 30px;
            text-align: center;
            margin-bottom: 20px;
            line-height: 30px;
            float: left;
          }
          .samples-panel {
            float: left;
            .sample-products {
              padding: 10px 15px;
              .sample-li-wrapper {
                float: left;
                width: 41%;
                padding-bottom: 20px;
                text-align: center;
                margin: 5px 10px;
                min-height: 230px;
                &.selected {
                  outline: 1px solid $color-grey;
                }
                input[type='checkbox'] {
                  display: none;
                }
                label {
                  display: block;
                }
                .sample-select-button {
                  display: none;
                }
              }
            }
          }
        }
      }
      .bottom-viewcart-buttons {
        border-bottom: 1px solid $color-white;
      }
    }
    .panel-header {
      h2 {
        a {
          text-decoration: underline;
        }
      }
    }
  }
  .messages {
    background-color: $color-white;
    border-bottom: 1px solid $color-white;
    color: $color-red;
    padding: 0 13px;
  }
  .shipping-panel {
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
    padding: 0 5px;
    .shipping-edit-content {
      padding: 10px;
      border: 1px solid $color-grey;
      border-top: 0px;
      .address-form {
        p {
          text-align: right;
        }
        &__fieldset {
          padding-top: 10px;
        }
        &__first-name-container {
          width: 35% !important;
          float: left;
        }
        label {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 5px;
        }
        &__title-container {
          width: 62% !important;
          float: left;
          margin-left: 5px;
          input[type='radio'] {
            width: auto;
          }
        }
        select {
          height: 45px;
        }
        &__state-container,
        &__city-container,
        &__district-container {
          width: 32% !important;
          float: left;
          margin-right: 2px;
        }
        .form-item {
          width: 100%;
          padding-right: 0;
        }
      }
      .delivery-info {
        border-top: 1px solid $color-grey;
        padding-top: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        select,
        textarea {
          height: 40px;
          width: 100%;
          border-radius: 3px;
        }
        .delivery-time {
          margin-bottom: 10px;
        }
      }
      .invoice-info {
        float: left;
        width: 49%;
        margin-top: 20px;
        .taxpayer_number {
          margin: 10px 0px;
        }
        input[type='checkbox'] {
          vertical-align: top;
        }
        label {
          width: 85%;
          .label-content {
            margin-left: 1em;
          }
        }
      }
      .giftwrap-info {
        float: right;
        width: 49%;
        margin-top: 20px;
        .form-item {
          width: 100%;
          padding-right: 0;
        }
        input[type='checkbox'] {
          vertical-align: top;
        }
        label {
          width: 85%;
          .label-content {
            margin-left: 1em;
          }
        }
      }
      input[type='submit'] {
        width: 100%;
        clear: both;
        margin: 20px 0px;
        height: 40px;
        line-height: 40px;
      }
    }
    &__title {
      color: $color-black;
      background-color: $color-grey;
      height: 36px;
      padding: 0 16px;
      line-height: 36px;
      margin-bottom: 0;
      a {
        color: $color-black;
      }
    }
    .shipping-address-display {
      width: 100%;
      float: left;
      padding: 10px;
      border-top: 0px;
      border: 1px solid $color-grey;
      .column {
        width: 100% !important;
      }
      .order_detail_link {
        padding: 10px;
        float: left;
        width: 100%;
        span {
          font-size: 16px;
          font-weight: bold;
        }
        a {
          float: right;
          text-decoration: underline;
          color: $color-black;
        }
      }
      .row {
        float: left;
        width: 100%;
        .trans_detail_item {
          float: left;
          width: 100%;
          padding: 5px 0;
          border-bottom: 1px dashed $color-grey;
          margin-bottom: 20px;
          padding-bottom: 10px;
          h4.checkout__panel--heading {
            display: inline-block;
            font-weight: bold;
          }
          a#panel--gift-edit {
            text-align: right;
            position: absolute;
            margin: 0;
            right: 18px;
            text-decoration: underline;
          }
          &.shipping-address-display__addresses,
          .gift-options-display {
            h3 {
              font-size: 15px;
              margin-bottom: 5px;
              letter-spacing: 0.15em;
              text-transform: uppercase;
              font-weight: bold;
            }
          }
        }
      }
    }
    .emailpromo-info {
      margin-top: 10px;
      input[type='checkbox'] {
        vertical-align: top;
      }
      label {
        width: 85%;
        .label-content {
          margin-left: 1em;
        }
      }
    }
  }
  #review_viewcart_panel {
    padding: 0 5px;
    float: left;
    width: 100%;
    .checkout__panel-title {
      color: $color-black;
      height: 36px;
      line-height: 36px;
      padding: 0 16px;
      background-color: $color-grey;
      margin-bottom: 0;
      a {
        color: $color-black;
      }
    }
    .content {
      padding: 10px;
      border: 1px solid $color-grey;
      border-top: 0px;
      .cart-header {
        border-bottom: 1px solid $color-white;
      }
    }
  }
  .review-panel {
    margin-bottom: 20px;
    padding: 0 5px;
    float: left;
    width: 100%;
    &__title {
      color: $color-black;
      height: 36px;
      line-height: 36px;
      padding: 0 16px;
      background-color: $color-grey;
      margin-bottom: 0;
    }
    .content {
      padding: 0;
      border: 1px solid $color-grey;
      border-top: 0px;
      #payment-method {
        table {
          width: 100%;
          td {
            width: 50%;
            font-size: 20px;
            text-align: center;
            border: 1px solid $color-grey;
            border-top: none;
            padding: 10px 0px;
            &.selected {
              border-right: 1px solid $color-black;
              border-bottom: none;
            }
          }
        }
        #selected_box {
          padding: 5px;
          width: 100%;
          border-left: 1px solid $color-black;
          border-right: 1px solid $color-black;
          border-bottom: 1px solid $color-black;
          .payone {
            .left {
              width: 51%;
              float: left;
              padding-top: 20px;
            }
            .right {
              text-align: center;
              width: 48%;
              float: right;
              padding-top: 20px;
            }
          }
          .submit {
            text-align: right;
            input[type='submit'] {
              margin-bottom: 10px;
            }
          }
        }
        .sms_code_container {
          font-size: 12px;
          margin-bottom: 10px;
          .mobile_phone_container {
            float: left;
            width: 100%;
            input[type='button'] {
              background: $color-black;
              color: $color-white;
              font-size: 14px;
              letter-spacing: 2.5px;
              text-transform: uppercase;
              border: 0;
              padding: 0 11px;
              margin: 10px 0;
              display: inline-block;
              height: 30px;
              line-height: 30px;
              width: auto;
            }
          }
          .verify_code_container {
            .error {
              background: url(/media/export/cms/account/sms/wrong_icon.png) no-repeat scroll 96% center;
              background-size: 15px 15px;
            }
            .success {
              background: url(/media/export/cms/account/sms/right_icon.png) no-repeat scroll 96% center;
              background-size: 15px 15px;
            }
          }
        }
      }
    }
  }
  input[type='text'],
  input[type='password'],
  input[type='email'] {
    height: 24px;
    border-radius: 3px;
    font-size: 12px;
    width: 100%;
  }
  .review_viewcart_subtotal {
    .order-status-table {
      float: right;
    }
  }
  &.confirm {
    padding: 30px 0px;
    margin-left: 32px;
    .checkout-progress {
      li {
        float: left;
        width: 32%;
        list-style: decimal;
      }
    }
    .confirmation-content {
      padding-top: 0;
      .confirmation-mail {
        h3 {
          font-size: 42px;
          margin: 25px 0px;
          padding-left: 13px;
          line-height: normal;
        }
      }
    }
    .return-link-container {
      padding-bottom: 20px;
    }
    .form-item {
      &.email-address,
      &.password {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }

  #togglemenus-collapsed, a.header-pc-hidden {
    display: none;
  }
}

#samples {
  .samples-panel {
    .samples {
      .samples-panel__title {
        font-size: 40px;
        padding-top: 20px;
        text-align: center;
      }
      .product-list {
        overflow: hidden;
        margin-top: 35px;
        .product {
          padding-bottom: 5px;
          margin-bottom: 20px;
          width: 100%;
          .product-img {
            width: 25%;
            float: left;
            padding-right: 15px;
            margin-bottom: 20px;
            img {
              width: auto;
            }
          }
          .details {
            width: 70%;
            float: left;
            text-align: left;
            line-height: 1.4;
            input {
              margin: 2px 2px 0px 0px;
              border-radius: 0;
              margin-left: 4px;
            }
          }
        }
      }
      .samples-buttons {
        padding: 0 13px;
        input[type='submit'] {
          width: 100% !important;
          line-height: 40px;
          height: 40px;
        }
      }
    }
  }
}

#sign-in-panel {
  float: left;
  padding: 0 5px;
  width: 100%;
  .signin-panel__title {
    color: $color-black;
    background-color: $color-grey;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    margin-bottom: 0px;
  }
  .content,
  .messages {
    padding: 10px;
    border: 1px solid $color-grey;
    border-top: 0px;
  }
  .form-item {
    margin: 1em 0;
    clear: both;
    label {
      margin: 5px 0;
      width: 100%;
    }
  }
  input[type='submit'] {
    margin: 10px 13px 20px 0px;
  }
  input[type='checkbox'] {
    float: left;
    margin-right: 5px;
    & ~ label {
      display: inline;
    }
  }
  .sign-in-component__email-promos {
    margin: 1em 0;
  }
  h4.sign-in-component__header {
    font-size: 20px;
    font-weight: bold;
  }
  .sign-in-page {
    .checkout_signin_guest_user,
    .checkout_signin {
      padding: 25px 0px;
      border-bottom: 1px solid $color-grey;
    }
    .checkout_signin_new_user {
      padding: 25px 0px;
      .birthday-container {
        select {
          width: 20%;
          margin-right: 0.5em;
        }
      }
    }
  }
}

.cart-item {
  padding: 20px 13px;
  position: relative;
  &__thumb {
    float: #{$ldirection};
    width: 25%;
    padding-right: 20px;
  }
  &__desc {
    float: left;
    width: 74%;
    line-height: 1.5;
    .column {
      float: #{$ldirection};
      width: 100%;
    }
    .product_subname {
      font-weight: bold;
    }
  }
  &__main_desc {
    margin-top: 20px;
    .column {
      float: left;
      width: 33%;
    }
  }
  &__remove-form {
    position: absolute;
    right: 0;
    top: 6px;
    width: 40px;
    font-size: 20px;
  }
}

.cart-items-bottom {
  float: left;
  padding: 10px 15px;
  border-top: 1px solid $color-white;
  width: 100%;
  .left.offer-code {
    float: left;
    width: 70%;
    #offer-code-panel {
      .offer_code_module {
        display: block;
        .offer_code_form_container {
          display: none;
          input[type='text'] {
            border-radius: 3px;
            width: 28%;
            height: 30px;
            margin-top: 15px;
            .form-submit {
              margin-top: 15px;
            }
          }
          input[type='submit'] {
            margin: 15px 0px 0px 2px;
            font-size: 9px;
            width: 76px;
            padding: 0px 5px;
          }
        }
      }
    }
  }
  .right {
    float: right;
    width: 100%;
    .order_head {
      p {
        padding-top: 10px;
        margin-bottom: 0;
      }
      h3 {
        font-weight: bold;
      }
    }
    .order-status-table {
      float: right;
      width: 100%;
      margin: 10px 0px 0px;
      tr.total {
        th {
          border-top: 1px solid $color-black;
          padding-top: 10px;
        }
        td.price {
          border-top: 1px solid $color-black;
          padding-top: 10px;
        }
      }
      th {
        line-height: 20px;
        padding-bottom: 10px;
      }
      td {
        text-align: #{$rdirection};
      }
    }
  }
}

#viewcart {
  .checkout {
    #wrapper {
      .offer_code {
        border-bottom: none;
        border-top: 1px solid $color-white;
        float: left;
        width: 100%;
        .order_head {
          font-weight: bold;
        }
        &_box {
          input[type='text'] {
            float: left;
            height: 38px;
            width: 56%;
          }
          input[type='submit'] {
            width: 40%;
          }
        }
      }
      .viewcart-panel__content {
        .cart-item__thumb {
          img {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}
