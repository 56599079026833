.section-offers {
  .header-large {
    &__text {
      text-align: center;
    }
  }
}

.mpp-container {
  .mpp__filters {
    display: none;
  }
}

.site-header__utility {
  .gnav-util__content {
    .cart-block__products {
      max-height: 350px;
    }
  }
}

.section-trustmark {
  .text-image-block {
    &__text {
      font-weight: 400;
      ul {
        li {
          padding-bottom: 10px;
          line-height: 1.5rem;
          font-size: 12px;
          list-style-type: disc;
          margin-left: 24px;
        }
      }
      h2 {
        font-weight: bold;
        &.title5s {
          font-size: 20px;
        }
        &.title {
          font-size: 18px;
        }
      }
    }
  }
}

.spp__container {
  .product-full__container {
    .product-social {
      padding-bottom: 20px;
      .share {
        .social-share__link {
          margin-right: 15px;
        }
        .weixinWrapper {
          .qrcodeTable {
            border: 1px solid $color-grey;
            padding: 15px;
            margin-top: 10px;
            display: inline-block;
            h3 {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

.footer {
  margin-bottom: 10px;
  .site-footer__main {
    margin-bottom: 0px;
    .site-footer__column {
      padding: 10px 0px;
      .menu__list {
        height: auto;
      }
      .site-footer__column {
        margin-left: 0px;
        .country-select {
          width: 60%;
        }
      }
    }
  }
  .site-footer__trustmark {
    z-index: 99;
  }
}

.ecommerce {
  .header-links {
    select,
    .selectBox {
      min-width: 200px;
      background: $color-white;
      margin: 10px 15px 10px;
    }
  }
  .basic-responsive {
    p {
      line-height: 1.5rem;
      padding: 0 15px;
    }
  }
}

.site-content {
  .product-brief {
    &__name {
      a {
        height: auto;
      }
    }
    &__description {
      font-weight: bold;
    }
    &__price {
      margin-left: 15px;
      padding-left: 18px;
    }
    &__cta {
      padding: 11px 20px;
    }
  }
  .product-full {
    &__subline {
      font-weight: bold;
    }
  }
  .esearch-product {
    &__content {
      font-size: 14px;
      font-weight: bold;
    }
    &__subline {
      font-size: 12px;
      height: 71px;
    }
    .product-brief {
      &__cta {
        padding: 11px 20px;
        line-height: 35px;
      }
    }
  }
  .spp__container {
    .product-full {
      &__btn__container {
        a {
          height: 50px;
        }
      }
    }
  }
  .product-sku-price {
    margin-left: 15px;
    padding-left: 20px;
    display: inline-block;
  }
}

.site-header__nav {
  .esearch-nav {
    #search {
      padding-right: 40px !important;
    }
  }
}

.front {
  .field-collection-slides {
    .basic-carousel-formatter,
    .basic-grid-formatter {
      .content-block-large__content {
        .button {
          display: none;
        }
      }
    }
  }
}

.section-collection-expert-skincare {
  .hero-large__inner {
    .hero-large__text {
      font-size: 2.2em;
      .expert-skincare-subtitle {
        font-size: 30px;
      }
    }
  }
}

.section-offers {
  .content-block-large__content {
    .button {
      display: none;
    }
  }
}

.section-gifts-and-sets {
  .product-brief__review-snippet {
    display: inline-block;
    margin: 0 auto;
  }
}

.product-grid-wrapper {
  .product-brief__cta-container {
    clear: both;
  }
}
.site-footer {
  .site-email-signup {
    display: none;
  }
}