.site-header {
  &__menu-list {
    .menu--lvl-1 {
      a[href='/gifts'] {
        color: $color-black !important;
      }
    }
  }
}

.device-mobile {
  .wo-inline {
    #wo_offline_image {
      position: fixed;
      right: 0;
      width: auto !important;
      z-index: 99;
    }
  }
  .site-footer {
    margin: 0 15px;
    &__column {
      margin-left: 0;
    }
  }
}

.section-customer-service-privacy-policy {
  .site-content {
    .cs-page {
      &__title {
        padding: 15px 20px 10px 15px;
        h1 {
          font-size: 24px;
        }
      }
      &__content {
        width: 100%;
        left: 100%;
      }
      &__content-item {
        padding: 0;
        ul {
          padding-left: 20px;
        }
        .customer-service-text {
          line-height: 23px;
          .section {
            margin: 0;
          }
          ul {
            margin-left: 0;
            font-weight: bold;
            font-size: 13px;
            text-decoration: underline;
            line-height: 23px;
            margin-top: 0;
            text-transform: capitalize;
            margin-bottom: 10px;
            float: left;
            width: 100%;
            li {
              list-style-type: none;
            }
          }
        }
      }
    }
    p {
      line-height: 1.45em;
    }
  }
}

.ecommerce {
  .site-content {
    .header-links {
      &__title {
        line-height: 1;
      }
      select {
        margin-top: 5px;
      }
    }
    .basic-responsive {
      p {
        line-height: 1;
      }
    }
    .product-brief__cta {
      padding: 0 20px;
    }
    .product-brief__price {
      margin-left: 0;
      padding-left: 0;
    }
  }
  &.section-about-skincare-basics {
    .basic-responsive {
      ul {
        padding-left: 20px;
      }
    }
    .header-large {
      color: $color-black;
      &__text {
        font-size: 28px;
        padding-top: 3px;
        span {
          color: $color-white !important;
        }
      }
      &--no-image {
        font-weight: bold;
      }
    }
    .product-brief {
      &__review-snippet {
        float: left;
        width: 100%;
      }
    }
  }
  &.section-customer-service-terms-conditions {
    .cs-page {
      &__content-item {
        .basic-responsive {
          p {
            line-height: 1.45em;
          }
        }
      }
    }
  }
  &.section-menforlabseries {
    .basic-formatter__item {
      padding-top: 0px;
    }
    .content-block-large {
      padding: 0;
    }
    .Bottom-carousel-menforlabseries {
      .content-block-large {
        &__inner {
          position: relative;
          top: -90px;
          font-size: 16px;
        }
        &__cta {
          background-color: $color-black;
          color: $color-white;
          a {
            color: $color-white;
            font-size: 14px;
          }
        }
      }
      .slick-prev {
        top: 66%;
        left: 30px;
        &:before {
          font-size: 25px;
          color: $color-slick-grey;
        }
      }
      .slick-next {
        top: 66%;
        margin-right: 30px;
        &:before {
          font-size: 25px;
          color: $color-slick-grey;
        }
      }
    }
  }
}

.lucky_draw_m {
  #content {
    .sumbit_btn,
    .allbtn {
      display: block;
      text-align: center;
      img {
        width: 135px;
      }
    }
    .page1_logn {
      top: 87%;
      left: 25%;
      right: 25%;
      width: auto;
      text-align: center;
    }
    .page_form {
      left: 0px;
      top: 58%;
      width: 100%;
      padding: 0px 5px;
    }
    .page3_form {
      position: absolute;
      top: 77%;
      left: 25%;
      width: auto;
      right: 25%;
      text-align: center;
      ul {
        li {
          text-align: center;
          float: none;
        }
      }
      a {
        margin-top: 0;
      }
    }
    .page4_form {
      top: 0;
      left: 0;
      position: relative;
      width: 100%;
      margin: 0 auto;
      .allbtn {
        position: absolute;
        bottom: 50px;
        left: 25%;
        right: 25%;
        width: auto;
        text-align: center;
        img {
          width: 135px;
        }
      }
    }
  }
}
