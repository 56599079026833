#viewcart {
  .checkout {
    #wrapper {
      .continue-button-sticky {
        padding-top: 0px;
        a.checkout-sticky-button {
          float: none;
          width: 100%;
          height: 3em;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 2.5em;
          padding: 3px 11px;
          display: inline-block;
          margin: 0px;
        }
      }
      .viewcart-panel.empty {
        .continue-button-sticky {
          display: none;
        }
      }
    }
  }
}

#samples {
  .checkout {
    #content {
      .continue-button-sticky {
        padding-top: 0px;
      }
      .samples-buttons.bottom {
        display: none;
      }
    }
  }
}

#viewcart,
#samples,
#index {
  .footer {
    margin-bottom: 80px;
  }
}

#index {
  .panel {
    display: none;
    .panel {
      display: block;
      &.finished {
        display: block;
      }
    }
  }
  .panel.active {
    display: block;
  }
  .finished.panel {
    display: none;
  }
  .review-panel.active + {
    section {
      display: block;
    }
  }
  #shipping-page-info-panel.finished {
    display: block;
  }
  .continue-button-sticky {
    padding-top: 0px;
    height: 6.5em;
    .checkout-sticky-button {
      margin: 0px;
    }
  }
  .shipping-page-info-panel {
    #continue-checkout-btm {
      .checkout-buttons {
        display: none;
      }
    }
  }
}

#wo_chatbox {
  #wo_online_image {
    right: 0%;
    width: auto !important;
  }
}

.checkout {
  .continue-button-sticky {
    box-shadow: none;
    .checkout-sticky-button {
      margin: 0px;
      font-size: 14px;
      letter-spacing: 2.5px;
      font-weight: normal;
    }
  }
}
