#registration {
  .account_page {
    #content {
      .profile-form-container {
        .form_element {
          input[type='checkbox'] {
            width: auto;
            float: #{$ldirection};
          }
          span {
            float: #{$ldirection};
            &.title_element {
              width: 100%;
              select {
                width: 100%;
              }
            }
          }
          #address-info-mobile {
            label {
              display: inline;
              .label-content {
                margin-left: 0;
              }
            }
          }
          &.title_container {
            float: #{$ldirection};
            width: 100%;
          }
          &.form-item {
            padding: 0;
            width: 100%;
          }
        }
        .registration-page__sms-verification {
          .sms_code_container {
            display: inline;
            input#sendSMSCode {
              width: auto;
              background-color: $color-black;
              border: none;
              padding: 0 11px;
              height: 30px;
              line-height: 30px;
              color: $color-white;
            }
          }
        }
      }
      .shipping_subscribe_today {
        .email_promotions {
          span {
            margin-left: 0;
          }
        }
      }
      .form_element {
        select,
        input[type='text'] {
          height: 38px;
          font-size: 12px;
        }
      }
      .address_form_container {
        input,
        select {
          width: 100%;
          border-radius: 3px;
        }
        label {
          margin: 5px 0;
          display: block;
        }
        span.required_mark {
          float: #{$ldirection};
        }
        .form_element {
          &.state_container,
          &.postal_code_container {
            padding: 0px 13px;
          }
        }
      }
      .submit_btn {
        input {
          background-color: $color-black;
          padding: 0 11px;
          height: 30px;
          line-height: 30px;
          color: $color-white;
        }
      }
      .gender_container {
        input ~ label {
          display: inline;
          margin-right: 10px;
        }
      }
    }
    .customer-service-content {
      padding: 0px 13px;
    }
  }
}

#address_book {
  #content {
    #address_form_container {
      padding: 20px;
      #address {
        .address {
          .form_element {
            select {
              width: 100%;
              height: 38px;
            }
            input[type='text'] {
              height: 38px;
            }
            input[type='checkbox'] {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
  #colorbox {
    .address-delete-confirm {
      padding: 20px;
    }
  }
  #myaccount-wrapper {
    .main-button-wrapper {
      margin: 20px;
    }
  }
}

#signin {
  .account_page {
    input {
      font-size: 12px;
    }
    #wrapper {
      #birthday_container {
        span {
          display: block;
          margin-bottom: 2px;
        }
        .form-item {
          width: 25%;
          display: inline-block;
        }
      }
    }
  }
}
.birthday {
  .change-birth-text {
    margin-top: 20%;
    color: $color-red;
  }
}
.account_page {
  #myaccount-wrapper,
  #wrapper,
  #content {
    #cart_items {
      .cart-item-rows {
        .prod-desc {
          .sub-name {
            display: block;
          }
        }
      }
    }
  }
}

#order_detail {
  .account_page {
    #content {
      .track_detail {
        border-top: 2px solid $color-grey-border;
        padding: 15px 13px;
        table {
          width: 100%;
          td {
            font-weight: 400;
            padding: 5px;
          }
          .date {
            width: 15%;
          }
          .weekday {
            width: 10%;
          }
        }
        .text-header {
          text-align: center;
        }
      }
    }
  }
}

#colorbox {
  .sms_popup {
    .sms_popup_bind_h2 {
      padding-top: 1em;
    }
  }
}

.sms_popup {
  text-align: center;
  .bd_phone {
    .mobile_bd_label {
      display: inline-block;
      width: 80px;
      text-align: left;
    }
  }
}
